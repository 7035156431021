import { PageProps } from 'gatsby';
import { ReservationData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import reservation from '~/params/reservation.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageReservationsUpdate: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params: { espaceId, id },
}) => (
  <TemplateEspace espace={espace} user={user}>
    <CMSView>
      <Form
        docId={id}
        model={
          new ReservationData({
            espaceId,
            params: reservation,
          })
        }
        type="update"
      />
    </CMSView>
  </TemplateEspace>
);

export default requireEspace(PageReservationsUpdate);
